import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../styles/contact.css" 

const Contact = () => (
    <Layout>
        <Seo title="Contact" keywords={[`email`, `contact`, `assignmentoperator.com`,]}/>
        <div id="contactContainer">
            <form 
                name="contact" 
                method="post" 
                action="https://formsubmit.co/6fafe79c40f6994608a9fd46044120d8"
                className="contact-form-style"
            >
                {/* FormSubmit Advanced Features! See: https://formsubmit.co/ */}
                <input type="hidden" name="_captcha" value="false" />
                <input type="text" name="_honey" style={{display: `none`}} />
                <input type="hidden" name="_next" value="https://assignmentoperator.com/success/" />
                <input type="hidden" name="_template" value="box" />
                
                <div><p>GET IN TOUCH</p></div>
               
                <div>
                    <input type="text" name="name" className="field-style field-full align-none" placeholder="Your Name" required />
                </div>
                <div>
                    <input type="email" name="email" className="field-style field-full align-none" placeholder="Your Email Address" required />
                </div>
                <div>
                    <input type="text" name="_subject" className="field-style field-full align-none" placeholder="Subject" required />
                </div>
                <div>
                    <textarea name="message" className="field-style" placeholder="Write your message here" required></textarea>
                </div>
                <div>
                    <button type="submit">Send</button>
                    <button type="reset">Clear</button>
                </div>
            </form>
        </div>
    </Layout>
)

export default Contact

// Ignore following words from checking by Code Spell Checker
// cSpell:ignore assignmentoperator netlify